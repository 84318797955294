import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import PageHero from "../components/page-hero";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../helper/TextSerializer";
import SEO from "../components/seo";
import PersonTile from "../components/personTile";
import Fader from "../helper/Fader";
import RepresentativeWork from "../components/expertise-list";

export const query = graphql`
  query ServiceTemplateQuery($id: String!) {
    page: allSanityPage(filter: { slug: { current: { eq: "expertise" } } }) {
      nodes {
        backgroundImage {
          asset {
            fixed(width: 1600, height: 900) {
              ...GatsbySanityImageFixed_withWebp_noBase64
            }
          }
        }
      }
    }
    service: sanityService(id: { eq: $id }) {
      id
      title
      pageSEO {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }
      slug {
        current
      }
      representativeWork
      _rawIntroCopy(resolveReferences: { maxDepth: 8 })
      _rawContent(resolveReferences: { maxDepth: 8 })
      relatedServices {
        title
        slug {
          current
        }
      }
      experts {
        id
        order
        slug {
          current
        }
        fullName
        role
        image {
          hotspot {
            x
            y
          }
          asset {
            fluid(maxWidth: 600, maxHeight: 600) {
              ...GatsbySanityImageFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

const RelatedServices = ({ services }) => {
  return services.length ? (
    <div className="service-related">
      <p>Related expertise: </p>
      <ul>
        {services.map((service, i) => {
          return (
            service?.slug?.current && (
              <li className="service-related-service" key={i}>
                <a href={`/expertise/${service.slug.current}`}>
                  {service.title}
                </a>
              </li>
            )
          );
        })}
        ;
      </ul>
    </div>
  ) : (
    ""
  );
};

const ServiceTemplate = ({ data }) => {
  const { page, service } = data;
  return (
    <Layout
      className="page-service-container"
      backgroundImage={page.nodes[0].backgroundImage?.asset?.fixed}
    >
      <SEO
        title={(service.pageSEO && service.pageSEO.metaTitle) || service.title}
        description={service.pageSEO && service.pageSEO.metaDescription}
        image={service.pageSEO && service.pageSEO.metaImage}
      />
      <PageHero
        title={service.title}
        intro={service._rawIntroCopy}
        content={
          <>
            <RelatedServices services={service.relatedServices} />
            <RepresentativeWork
              title="Service Areas &amp; Representative Work"
              representativeWork={service.representativeWork}
              device="tablet"
            />
          </>
        }
      />

      {(service._rawContent ||
        service.experts[0] ||
        service.representativeWork[0]) && (
        <Fader>
          <section className="service-body page-body">
            <div className="service-body-inner">
              <RepresentativeWork
                title="Service Areas &amp; Representative Work"
                representativeWork={service.representativeWork}
                device="desktop"
              />
              <div className="service-content">
                <div className="service-content-block">
                  {service._rawContent && (
                    <BlockContent
                      blocks={service._rawContent}
                      serializers={{
                        types: {
                          block: serializers.types.block,
                          image: serializers.image,
                          youtube: serializers.types.youtube,
                        },
                        marks: serializers.marks,
                      }}
                    />
                  )}
                </div>
                {service.experts[0] && (
                  <div className="service-experts">
                    <p className="service-experts-heading caption">
                      {`For ${service.title.toLowerCase()} related enquiries:`}
                    </p>
                    <div className="people-tiles">
                      {service.experts.map((person, i) => (
                        <PersonTile
                          key={i}
                          person={person}
                          aspectRatio={4 / 3}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </Fader>
      )}
    </Layout>
  );
};

export default ServiceTemplate;
