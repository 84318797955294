import React from "react";
import { Link } from "gatsby";

const RepresentativeWork = props => {
  const { title, representativeWork, device } = props;
  if (representativeWork[0]) {
    return (
      <div
        className={`service-representative -${device}`}
        aria-hidden={device === "tablet" ? "true" : "false"}
      >
        <div className="service-representative-inner">
          <h4 className="representative-heading">{title}</h4>
          <ul className="representative-list">
            {representativeWork.map((work, i) => {
              return (
                <>
                  {work?.title && work?.slug?.current && (
                    <li key={i}>
                      <Link to={`/expertise/${work.slug.current}`}>
                        {work.title}
                      </Link>
                    </li>
                  )}
                  {!work?.title && <li key={i}>{work}</li>}
                </>
              );
            })}
          </ul>
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export default RepresentativeWork;
